/* navigation css */
.navbar-nav {
  width: 100%;
  justify-content: center;
}

.navbar {
  background-color: #335d87;
  padding: 16px 0 2px 0 !important;
}

.nav-item > .nav-link {
  font-size: 16px;
  font-weight: 600;
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.3rem !important;
  padding-right: 1.3rem !important;
  text-align: center;
  display: table-cell;
  height: 64px;
  vertical-align: middle;
  border-radius: 30px;
  cursor: pointer;
}

.nav-item > .nav-link:hover {
  color: white;
}

.navbar-toggler-icon {
  background-image: url('assets/images/nav-icon.png') !important;
}

.navbar-toggler {
  border: 0px !important;
}

.navbar-toggler:focus {
  box-shadow: unset !important;
}

.nav-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu-div {
  position: absolute !important;
  background-color: #335d87 !important;
  padding: 0 !important;
  min-width: 160px !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2) !important;
  z-index: 1 !important;
  border-top-color: white !important;
  left: -25px !important;
  display: block !important;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  /* transition: all 0.5s ease; */
}

.logout-menu {
  margin-top: 1px;
  background-color: #ffffff !important;
  left: 0 !important;
  min-width: 215px !important;
}

.nav-item:hover > .dropdown-menu-div,
.nav-item:active > .dropdown-menu-div {
  max-height: 800px;
  visibility: visible;
  transition: all 2s ease 0.2s;
}

.dropdown-menu-div a,
.nav-link a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.logout-menu > a {
  color: #335d87;
  font-size: 14px;
}

.dropdown-menu-div a:hover,
.dropdown-menu-div a:focus,
.dropdown-menu-div a:active {
  background-color: unset;
  color: white;
}

.logout-menu a:hover {
  background-color: #fff;
  color: #335d87;
}

.nav-dropdown:hover .dropdown-menu-div {
  display: block;
}

.nav-span {
  display: flex;
}

.nav-down-arrow {
  display: none;
}

.nav-logout-arrow {
  display: inline;
  margin-left: 8px;
  margin-top: -3px;
  width: 11px;
}

.logout-div {
  display: flex;
  text-align: left;
}

.logout-span {
  color: white;
  font-size: 14px;
  margin-left: 10px;
}

.logout-email {
  font-size: 12px;
}

.logout-dropdown:hover div > img {
  transform: rotate(180deg);
}

.logout-dropdown:hover div > span {
  color: white;
}

.logout-btn {
  background-color: transparent !important;
  border: transparent !important;
}

.logout-btn::after {
  display: none !important;
}

.logout-btn-options {
  width: 100%;
}

.logout-btn-options > a {
  padding: 11px 16px;
}

.logout-btn:hover,
.logout-btn:active {
  background-color: transparent !important;
  border: transparent !important;
}
.url {
  color: #175cd3 !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.nav-log-in {
  background-color: #c32051;
  border-radius: 8px;
  padding: 10px 25px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: white !important;
  transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
  border: 2px solid #fff !important;
  cursor: pointer;
}
.mui-accordion {
  border: 1px solid #000;
  border-radius: 8px !important;
}

.nav-log-in:hover {
  background-color: #99103d !important;
}

.nav-mobile-btn {
  display: flex;
  align-items: center;
}

/* For Tablet */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* .nav-log-in {
    display: none !important;
  } */

  .nav-item > .nav-link {
    font-size: 14px;
    padding-bottom: 0;
    width: 332px;
    text-align: left;
  }

  .nav-down-arrow {
    margin-left: 30px;
    width: 5%;
    float: right;
    margin-top: 4px;
    display: block;
  }

  .nav-item:nth-child(6) > .nav-link {
    white-space: nowrap;
  }

  .nav-mobile-btn {
    display: flex;
  }

  .nav-mobile-btn > p {
    margin: 5px 28px 0 0;
  }

  .nav-mobile-btn > p > a {
    color: white;
    font-size: 16px;
  }

  .dropdown-menu-div {
    position: static !important;
    border: unset !important;
    box-shadow: unset !important;
  }

  .dropdown-item-link {
    padding: 3px 52px !important;
    font-size: 14px !important;
  }

  .nav-item:nth-child(5) > .nav-link {
    display: none;
  }

  .driver-trip .trip-item {
    max-width: 100% !important;
  }
}

/* For Mobile */
@media screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .nav-log-in {
    padding: 6px 22px !important;
    margin-left: 50px;
  }

  .nav-mob-profile {
    max-width: 200px;
  }

  .nav-down-arrow {
    margin-left: 30px;
    width: 7%;
    float: right;
    margin-top: 4px;
    display: block;
  }

  .nav-item > .nav-link {
    font-size: 14px;
    height: 42px;
    text-align: left;
    padding-left: 50px !important;
    width: 77%;
  }

  .nav-item:nth-child(5) > .nav-link {
    display: none;
  }

  .nav-item {
    display: contents;
  }

  .nav-mobile-btn {
    display: flex;
  }

  .nav-mobile-btn > p {
    margin: 5px 28px 0 0;
  }

  .nav-mobile-btn > p > a {
    color: white;
    font-size: 16px;
  }

  .dropdown-menu-div {
    position: static !important;
    border: unset !important;
    box-shadow: unset !important;
  }

  .logout-btn-options > a {
    padding: 11px 16px;
  }

  .logout-btn {
    padding-right: 0;
  }

  .logout-span {
    font-size: 12px;
  }

  .logout-email {
    font-size: 10px;
  }

  .logout-profile-icon {
    max-width: 21% !important;
  }

  .dropdown-item-link {
    padding: 3px 74px !important;
    font-size: 14px !important;
  }

  .vertical-line {
    left: 40px !important;
  }

  .start-point {
    margin-bottom: -15px !important;
    margin-left: 8px !important;
  }

  .stop-point {
    margin-top: -15px !important;
    margin-left: 8px !important;
  }

  .from_location {
    margin-bottom: -5px !important;
    font-size: 10px !important;
    margin-left: 50px !important;
  }

  .to_location {
    margin-top: -5px !important;
    font-size: 10px !important;
    margin-left: 50px !important;
  }

  .driver-trip .trip-item {
    max-width: 100% !important;
  }

  .driver-trip {
    padding: 20px 30px !important;
  }
}

/* navigation css */

/* footer css */

.footer-bg {
  background-color: white;
}

.footer-a {
  text-decoration: unset;
  line-height: 50px;
  color: #4e4e4e;
}

.footer-a:hover {
  color: #4e4e4e;
}

.footer-heading {
  color: #4e4e4e;
  font-weight: 600;
}

/* For Tablet */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .footer-a {
    font-size: 14px;
    line-height: 40px;
  }

  .footer-heading {
    font-size: 14px;
  }
}

/* For Mobile */
@media screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .footer-bg {
    background-color: #335d87;
    padding: 10px 0px;
  }

  .footer-a {
    font-size: 16px;
    color: white;
  }

  .footer-p {
    display: table-cell;
    height: 64px;
    vertical-align: middle;
    width: 130px;
  }

  .footer-logo-parent {
    display: flex;
    justify-content: center;
    height: 57px;
  }
}

/* footer css */

/* home page css */

.parent-heading {
  text-align: center;
  padding-bottom: 10px;
  font-size: 36px;
}

.home-paragraph {
  font-size: 24px;
  line-height: 28.13px;
  text-align: center;
  margin-bottom: 30px;
}

.home-section-three {
  border-top: 1px solid #335d87;
  border-bottom: 1px solid #335d87;
  padding: 40px 140px;
  padding-bottom: 10px;
}

.home-paragraph > span {
  color: #c30251;
  font-weight: 500;
}

/* For Tablet */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .home-section-three {
    padding: 30px 20px;
    padding-bottom: 0px;
  }

  .home-paragraph {
    font-size: 18px;
  }
}

/* For Mobile */
@media screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .home-section-three {
    padding: 18px 0px;
    padding-bottom: 0px;
    border-bottom: 0;
  }

  .home-paragraph {
    font-size: 16px;
    line-height: 18.13px;
    margin-bottom: 16px;
  }
}

/* home page css */

/* request form page css */

.custom-tooltip {
  position: relative;
  border-bottom: 1px dotted black;
}

.tooltiptext {
  width: 90%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 11px 19px;
  position: relative;
  z-index: 1;
  bottom: 100%;
  left: 44%;
  margin-top: -132px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 300;
  float: right;
  visibility: hidden;
}

.custom-tooltip:hover ~ .tooltiptext {
  visibility: visible;
}

.custom-tooltip ~ .tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.attachment-del-icon {
  margin-right: 32px;
}

.file-name-label {
  font-weight: 700;
  font-size: 22px;
  margin-left: 64px;
}

.purchase-second-section {
  border-top: 1px solid #335d87;
  padding: 40px 140px;
  padding-bottom: 10px;
}

.purchase-third-section {
  margin-top: 55px;
}

.purchase-third-section-h3 {
  border-bottom: 1px solid #335d87;
  font-weight: 400;
  padding-bottom: 10px;
  font-size: 36px;
}

.form-label {
  margin-bottom: 5px;
}

.purchase-text {
  text-align: left;
}

.modal-field-label {
  text-align: left;
  color: #000;
}

.modal-submit-btn {
  font-size: 14px;
  padding: 10px 18px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 6px;
  background-color: #335d87;
  color: #ffffff;
  border: 2px solid #335d87;
}

.modal-cancel-btn {
  margin-right: 10px;
  background-color: transparent;
  color: #335d87;
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 6px;
  border: 2px solid #335d87;
}

.net-vendor-submit-btn {
  font-size: 14px;
  padding: 10px 18px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 6px;
  background-color: #335d87;
  color: #ffffff;
  border: 2px solid #335d87;
  float: right;
}

.net-vendor-discard-btn {
  margin-right: 10px;
  background-color: transparent;
  color: #335d87;
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 6px;
  border: 2px solid #335d87;
  float: right;
}

.purchase-text input,
.modal-field-label input,
.purchase-text select,
.modal-field-label select,
.purchase-text textarea,
.modal-field-label textarea,
.phone-input {
  border-radius: 3px;
}

.phone-input input {
  height: 54px;
  border: 0.5px solid #cbcbcb;
}

.purchase-text input:focus,
.modal-field-label input:focus,
.purchase-text select:focus,
.modal-field-label select:focus,
.purchase-text textarea:focus,
.modal-field-label textarea:focus {
  box-shadow: unset;
}

.purchase-attachment-btn {
  font-weight: 600;
  padding: 11px 23px;
  font-size: 16px;
  text-transform: uppercase;
  background-color: #335d87;
  margin-top: 16px;
  cursor: pointer;
  border: 2px solid #000000;
  border-radius: 6px;
  color: white;
}

.attachment-btn {
  font-weight: 600;
  padding: 6px 20px;
  font-size: 16px;
  text-transform: uppercase;
  background-color: #335d87;
  cursor: pointer;
  border: 2px solid #000000;
  border-radius: 6px;
  color: white;
  width: max-content;
}

.attachment-btn:hover {
  color: #335d87;
  background-color: #f2f2f2;
}

.attachment-btn > input {
  display: none;
}

.attachment > input {
  display: none;
}

.margin-lr {
  margin: 0 10%;
}

.purchase-attachment-btn:hover {
  color: #335d87;
  background-color: #f2f2f2;
}

.po-billing-div {
  min-height: 110px;
}

.po-modal-h6 {
  margin: 30px 0;
}

.purchase-text img {
  cursor: pointer;
  margin-right: 5px;
}

.po-modal-btn {
  font-size: 14px;
  color: #335d87;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 36px;
  border: 1px solid #000000;
  border-radius: 6px;
  font-weight: 600;
  margin-bottom: 40px;
  margin-top: 20px;
}

.purchase-order-btn {
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 6px;
  background-color: #335d87;
  color: #ffffff;
  border: 2px solid #000000;
  padding: 16px 60px;
  margin-top: 55px;
}

.purchase-order-btn:hover {
  background-color: #f2f2f2;
  color: #335d87;
}

.purchase-submit-btn {
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 6px;
  background-color: #c32051;
  color: #ffffff;
  border: 2px solid #000000;
  padding: 14px 98px;
  margin-top: 55px;
  width: max-content;
  margin-bottom: 50px;
}

.purchase-submit-btn:hover {
  background-color: #f2f2f2;
  color: #c32051;
  border: 2px solid #c32051;
}

.modal-form {
  background-color: #f2f2f2;
  border-radius: 0 0 20px 20px;
  padding: 30px 0;
}

.modal-form-padding {
  padding: 30px;
}

.modal-actions {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.po-number {
  background: #4e4e4e;
  color: #fff;
  padding: 4px 6px;
  border-radius: 3px;
}

.po-item-count {
  background-color: #c32051;
  color: #fff;
  padding: 4px 6px;
  border-radius: 3px;
}

.modal-header {
  background-color: #335d87;
  border-radius: 10px 10px 0px 0px;
  /* margin-top: 15px; */
}

.modal-header-h3 {
  width: 92%;
  padding: 22px 0;
  color: #ffffff;
  margin: 0 auto;
  margin-bottom: 0;
}

.purchase-img-icon {
  float: right;
  margin-top: 5px;
}

.purchase-card {
  padding: 40px 280px;
  justify-content: center;
}

.purchase-main-card {
  /* border: 2px solid #335d87; */
  border-radius: 20px;
  background-color: #efefef;
  margin-bottom: 20px;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.puchase-card-body {
  padding: 1rem;
}

.item-status {
  width: max-content;
  background: #4e4e4e;
  color: #fff;
  padding: 1px 15px 3px 15px;
  border-radius: 17px;
  font-size: small;
}

.urgent-status {
  background: #c32051 !important;
  margin-right: 5px;
}

.purchase-card-data {
  font-size: 24px;
  text-align: left;
  padding: 0 47px;
  margin-top: 29px !important;
  color: #1f1f1f;
}

purchase-card-data > div > p {
  margin-bottom: 7px;
}

.purchase-edit-icon {
  float: left;
  padding: 20px 0 0 40px;
}

.purchase-del-icon {
  float: right;
  padding: 20px 54px 0 0;
}

.purchase-attachment-btn > input,
.add-stop-attachement-btn > input {
  display: none;
}

.mobile-upload-icon {
  display: none;
}

.red-border {
  border: 1.9px solid #ff0000 !important;
}

.red-error {
  color: #ff0000;
}

.required {
  color: #c32051;
}

/* width is 1024px or greater */
@media screen and (min-width: 1024px) {
  .procurement-vendor {
    padding-left: 2rem !important;
  }
}

/* For Tablet */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .modal-form {
    padding: 10px;
  }

  .procurement-vendor {
    padding-left: 1rem !important;
  }

  .tooltiptext {
    left: 10%;
    margin-top: -150px;
  }

  .custom-tooltip ~ .tooltiptext::after {
    left: 87%;
  }

  .mobile-upload-icon {
    display: none;
  }

  .file-name-label {
    font-size: 20px;
  }

  .purchase-second-section {
    padding: 40px 0px;
  }

  .modal-header-h3 {
    padding: 15px 0;
  }

  .purchase-attachment-btn {
    padding: 12px 42px !important;
    margin-top: 30px;
  }

  .purchase-third-section-h3 {
    font-size: 26px;
  }

  .purchase-card {
    padding: 15px 0;
  }

  .purchase-card-data {
    font-size: 20px;
  }

  .purchase-submit-btn {
    margin-top: 35px;
  }

  .purchase-card-data {
    padding: 0 25px;
  }

  .purchase-edit-icon {
    padding: 20px 0 0 17px;
  }

  .purchase-del-icon {
    padding: 20px 17px 0 0;
  }
}

/* For Mobile */
@media screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .tooltiptext {
    width: 90%;
    left: 22%;
    margin-top: -270px;
  }

  .custom-tooltip ~ .tooltiptext::after {
    left: 80%;
  }

  .mobile-upload-icon {
    display: inline-block;
  }

  .file-name-label {
    font-size: 17px;
    margin-left: 0;
  }

  .modal-form {
    padding: 30px 21px;
  }

  .purchase-second-section {
    padding: 40px 15px;
  }

  .purchase-attachment-btn {
    display: none;
  }

  .purchase-card {
    padding: 40px 0;
  }

  .purchase-card-data {
    font-size: 16px;
    padding: 0 0px;
  }

  .purchase-edit-icon {
    padding: 20px 0 0 0;
  }

  .purchase-del-icon {
    padding: 20px 0 0 0;
  }

  .purchase-third-section-h3 {
    font-size: 26px;
  }

  .parent-heading {
    font-size: 30px;
  }
}

/* request form page css */

/* order request page css */

.table-section {
  border-top: 1px solid #335d87;
  padding-top: 20px;
  padding-bottom: 10px;
}

.head-tr {
  border: 1px solid #000000;
  background-color: #f2f2f2;
}

.body-tr {
  /* border: 1px solid #335d87; */
  line-height: 23px;
  vertical-align: middle;
}

.head-tr th {
  line-height: 19px;
  vertical-align: middle;
}

.vendor-select {
  font-size: 12px !important;
  border: 1.5px solid #335d87 !important;
  border-radius: 2px !important;
}

.project-select {
  font-size: 16px !important;
  border: 1.5px solid #335d87 !important;
  border-radius: 2px !important;
}

.vendor-select:focus,
.project-select:focus {
  box-shadow: unset !important;
}

.icon-div {
  display: inline-flex;
  width: max-content;
  align-items: center;
}

.icon-div > div {
  margin-right: 10px;
  cursor: pointer !important;
}

.message-icon,
.check-icon,
.car-icon,
.link-icon {
  margin-right: 10px;
  cursor: pointer !important;
}

.item-request-btn {
  font-weight: 600;
  border-radius: 6px;
  background-color: #335d87;
  font-size: 14px;
  color: white;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 30px;
  border: 0;

  margin-right: 10px;
}

.admin-workspace-btn {
  font-weight: 600;
  border-radius: 6px;
  background-color: #335d87;
  font-size: 14px;
  color: white;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 30px;
  border: 0;
  width: max-content;
}

.admin-workspace-qb-alerts-btn {
  background-color: #bc3a31;
  margin-right: 10px;
}

.admin-workspace-create-btn {
  background-color: #335d87;
}

.order-request-btn-row {
  margin-bottom: 21px;
}

.modal-submit-action-btns {
  margin-top: 50px;
}

.modal-submit-action-btns button {
  font-size: 14px;
  color: white;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 36px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  font-weight: 600;
  width: 104px;
}

.modal-submit-action-btns button:first-child {
  margin-right: 30px;
}

.item-request-btn-submit {
  margin-right: 37px;
}

.switch-btn {
  position: relative;
  display: inline-block;
  width: 171px;
  height: 40px;
}

.switch-btn input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-btn-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4e4e4e;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-btn-slider:before {
  position: absolute;
  content: '';
  height: 32px;
  width: 33px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.switch-btn input:checked + .switch-btn-slider {
  background-color: #c32051;
}

.switch-btn input:focus + .switch-btn-slider {
  box-shadow: 0 0 1px #c32051;
}

.switch-btn input:checked + .switch-btn-slider:before {
  -webkit-transform: translateX(130px);
  -ms-transform: translateX(130px);
  transform: translateX(130px);
}

/* Rounded sliders */
.switch-btn-slider.switch-btn-round {
  border-radius: 34px;
}

.switch-btn-slider.switch-btn-round:before {
  border-radius: 50%;
}

.switch-btn .switch-btn-labels {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 16px;
  font-family: sans-serif;
  transition: all 0.4s ease-in-out;
}

.switch-btn .switch-btn-labels::after {
  content: attr(data-off);
  position: absolute;
  right: 17px;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease-in-out;
  padding-top: 1px;
}

.switch-btn .switch-btn-labels::before {
  content: attr(data-on);
  position: absolute;
  left: 15px;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
  padding-top: 1px;
}

.request-header-btns {
  padding-right: 5em !important;
}

.switch-btn input:checked ~ .switch-btn-labels::after {
  opacity: 0;
}

.switch-btn input:checked ~ .switch-btn-labels::before {
  opacity: 1;
}

.switch-btn-align {
  text-align: left;
  padding-left: 3rem !important;
}

.modal-dialog {
  text-align: center !important;
  justify-content: center !important;
}

.order-request-modal-content .modal-content {
  width: 342px !important;
  height: 242px !important;
  background-color: #335d87 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
}

.request-form-modal-content .modal-content {
  width: 342px !important;
  height: 387px !important;
  background-color: #335d87 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
}

.request-form-modal-btn {
  background-color: #c32051;
  width: 252px;
  height: 52px;
  border: 2px solid #ffffff;
  border-radius: 6px;
  margin-top: 52px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 36px;
  font-weight: 600;
}

.request-form-modal-btn:hover {
  background-color: #f2f2f2;
  color: #c32051;
  border: 2px solid #c32051;
}

.modal-body {
  padding: 20px 20px 40px 20px !important;
}

.modal-p1 {
  font-weight: 600 !important;
  padding-top: 20px;
  margin: 0 auto;
}

.modal-p3 {
  font-weight: 600 !important;
  padding-top: 6px;
  margin: 0 auto;
}

.modal-p2 {
  height: 48px !important;
  padding: 27px 12px 0 10px;
  margin-bottom: 18px;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.request-form-modal-p {
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  width: 318px !important;
  height: 48px !important;
  padding: 27px 12px 0 10px;
  margin-bottom: 18px;
}

.request-form-modal-h3 {
  color: #fff;
  height: 80px;
  padding-top: 17px;
}

.modal-yes {
  background-color: #c32051;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 36px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  font-weight: 600;
}

.modal-yes:hover {
  color: #c32051;
  background-color: #ffffff;
  border: 1px solid #c32051;
}

.po-modal-download {
  background-color: #c32051;
  font-size: 14px;
  color: white;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 36px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  font-weight: 600;
}

.po-modal-download:hover {
  color: #c32051;
  background-color: #ffffff;
  border: 1px solid #c32051;
}

.modal-no {
  background-color: #335d87;
  font-size: 14px;
  color: white;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 36px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  font-weight: 600;
  margin-right: 50px;
}

.modal-no:hover {
  color: #335d87;
  background-color: #ffffff;
  border: 1px solid #335d87;
}

.data-modal .modal-header {
  border: 0;
}

.data-table-img {
  margin: 0 auto;
}

.data-modal .modal-body {
  padding: 17px 12px !important;
}

.vendor-modal .modal-body {
  padding: 0 !important;
}

.data-modal .modal-content {
  max-width: 455px !important;
  min-height: 500px !important;
  background-color: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  border-radius: 8px !important;
  display: block;
  overflow-y: initial !important;
  color: #000 !important;
}

.qb-msg-modal .modal-content {
  background-color: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  border-radius: 8px !important;
  display: block;
  overflow-y: initial !important;
  color: #000 !important;
}

.data-modal .messages {
  height: 32vh;
  overflow-y: auto;
}

.message-item {
  width: 300px;
  /* height: 142px; */
  padding: 12px;
  border-radius: 12px;
  margin: 0 auto;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.message-item div {
  padding: 0 12px;
  display: inline;
}

.message-item .name-span {
  background: #335d87;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  color: #ffffff;
  float: left;
}

.message-item .time-span {
  background: rgba(51, 51, 51, 0.8);
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  float: right;
  color: #ffffff;
}

.chatbox .chat-div {
  display: grid;
  grid-template-columns: 8fr 2fr;
  margin-top: 20px;
  width: 100%;
  padding: 10px;
}

.message-modal-button {
  border: 0;
  background-color: transparent;
}

.chatbox .chat-div .chat-input {
  width: 100%;
  border: 1px solid #335d87;
  border-radius: 2px;
  background: #f2f2f2;
  padding: 12px 16px;
  margin-right: 11px;
}

.table-dropdown {
  border-radius: 3px !important;
  border: 1.9px solid #335d87 !important;
}

.table-dropdown:focus {
  box-shadow: unset !important;
}

.search-requests {
  width: 100%;
  padding: 5px 10px;
  background: #ffffff;
  border: 1.5px solid rgba(51, 93, 135, 0.5);
  border-radius: 2px;
}

.table-search-input {
  width: 100%;
  padding: 7px 10px;
  background: #ffffff;
  border: 1.5px solid rgba(51, 93, 135, 0.5);
  border-radius: 2px;
}

.search-requests-div {
  padding: 5px 0 0 0 !important;
  margin: 0 auto;
  margin-bottom: 10px;
}

/* For Tablet */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .switch-btn-align {
    text-align: left !important;
    padding-left: 3rem !important;
  }

  .request-header-btns {
    padding-right: 1em !important;
  }

  .switch-btn input:checked + .switch-btn-slider:before {
    transform: translateX(106px);
  }

  .switch-btn .switch-btn-labels::after,
  .switch-btn .switch-btn-labels::before {
    font-size: 15px;
    padding-top: 1px;
  }

  .switch-btn {
    width: 147px;
  }

  .table-section {
    padding-top: 20px;
  }

  .head-tr th {
    line-height: 17px;
    font-size: 13px;
  }

  .body-tr {
    line-height: 19px;
    font-size: 13px;
  }

  .message-icon,
  .check-icon,
  .car-icon {
    width: 17px;
    /* margin-right: 2px; */
  }

  .link-icon {
    width: 17px;
  }

  .project-select {
    font-size: 13px !important;
  }

  .item-request-btn {
    font-size: 12px;
    padding: 10px 22px;
  }

  .item-request-btn-submit {
    margin-right: 26px;
  }

  .form-select {
    background-position: right 0.15rem center !important;
    padding: 0.375rem 1.25rem 0.375rem 0.75rem !important;
  }

  .data-modal .modal-content {
    min-height: 550px !important;
  }
}

/* For Mobile */
@media screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .switch-btn-align {
    text-align: unset;
    padding-left: unset !important;
  }

  .switch-btn .switch-btn-labels::after,
  .switch-btn .switch-btn-labels::before {
    padding-top: 0px;
  }

  .search-requests-div {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .table-section {
    padding-top: 20px;
  }

  .icon-div {
    width: max-content;
  }

  .head-tr th,
  .body-tr {
    font-size: 13px;
  }

  .form-select {
    padding: 0.375rem 1.25rem 0.375rem 0.35rem !important;
    background-position: right 0.175rem center !important;
  }

  .project-select {
    font-size: 12px !important;
  }

  .data-modal .modal-content {
    width: 320px !important;
    min-height: 450px !important;
  }

  .data-modal .messages {
    height: 41vh;
  }

  .message-item {
    width: 259px;
    height: 122px;
    padding: 8px;
    font-size: 14px;
  }

  .message-item p {
    margin-bottom: 10px;
  }

  .message-item .name-span,
  .message-item .time-span {
    padding: 4px 6px;
  }

  .chatbox .chat-div .chat-input {
    width: 226px;
    height: 55px;
  }

  .chatdiv img {
    width: 12%;
  }
}

/* order request page css */
/* Carousel  Start*/
.splide-custom-arrows .splide__arrow {
  z-index: 2;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  top: 50%;
  transform: translateY(-50%);
}
.splide-custom-arrows .splide__arrow--prev {
  left: -10px;
}

.splide-custom-arrows .splide__arrow--next {
  right: -10px;
}
/* Carousel  End*/

/* procurement workspace page css */

.procurement-vendor {
  text-align: left;
}

.procurement-search-div {
  margin: 29px 0 0 0 !important;
}

.procurement-po {
  margin-top: 31px !important;
  text-align: right !important;
  padding-right: 2rem !important;
}

.modal-loader-div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #ffffffbf;
  z-index: 10001;
}

.modal-loader-div > svg {
  vertical-align: middle;
  height: 100%;
}

.procurement-modal-content .modal-content {
  width: 342px !important;
  height: 242px !important;
  background-color: #335d87 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
}

.procurement-form-modal-p {
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  width: 318px !important;
  padding: 27px 12px 0 10px;
  margin-bottom: 18px;
}

.css-1u0eh3h-MuiPaper-root {
  position: relative;
}

.body-tr > td > input {
  border: 0px;
  text-align: center;
  width: 50px;
}

.input-div {
  border: 0px;
  text-align: center;
  width: 200px;
}

.body-tr > td > input:focus {
  outline: none;
}

tr td:last-child > input {
  width: 160px;
}

.sub-table-parent-tr {
  font-weight: 600;
  border-bottom: 4px solid #80808075;
  border-left: 1px solid #8080807a;
  border-right: 1px solid #8080807a;
}

.sub-table-tr {
  border-left: 1px solid #8080807a;
  border-right: 1px solid #8080807a;
  border-bottom: transparent;
}

tbody > .sub-table-parent-tr.d-table-row,
tbody > .sub-table-tr.d-table-row {
  transition: opacity 1s ease-in-out;
}

tbody > .sub-table-parent-tr.d-none,
tbody > .sub-table-tr.d-none {
  opacity: 0;
}

/* purchase order modal css */

.procurement-po-modal-content .modal-content {
  width: 700px !important;
  height: 900px !important;
  background-color: #335d87 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
}

.po-h3 {
  font-size: 17px;
  width: 78%;
  margin: 0 auto;
  color: #fff;
}

.po-img-div {
  margin: 0 !important;
}

.po-do-btn-width {
  width: 50%;
  margin: 0 auto;
}

.po-card {
  width: 496px;
  height: 650px;
  margin: 0 auto;
  padding: 10px;
  margin-top: 42px;
  margin-bottom: 50px;
}

.po-card-data {
  padding: 30px 55px;
  color: #000;
}

.po-left-align {
  text-align: left;
}

.po-p {
  font-size: 10px;
  margin: 0;
}

.po-p-margin {
  margin-left: 40px;
}

.po-head {
  margin-top: 4px;
  background-color: #4988dd;
  color: #fff;
  padding: 1px 0 1px 13px;
  font-size: 14px;
}

.po-first-table > thead > tr > th {
  border: 1px solid #000;
  font-size: 10px;
  background-color: #4988dd;
  text-align: left;
  color: #fff;
  padding-left: 3px;
}

.po-first-table > tbody > tr > td {
  border: 1px solid #000;
  font-size: 9px;
  text-align: left;
  padding-left: 3px;
}

.po-second-table > tbody > tr > td {
  border: 1px solid #000;
  font-size: 9px;
  text-align: left;
  padding-left: 3px;
}

.div-po-second-table {
  min-height: 215px;
}

.po-second-table > thead > tr > th {
  border: 1px solid #000;
  font-size: 10px;
  background-color: #4988dd94;
  text-align: left;
  color: #000;
  padding-left: 3px;
  text-transform: uppercase;
  font-weight: 400;
}

.po-first-table,
.po-second-table {
  width: 100%;
  margin-top: 14px;
}

.po-instruction-head {
  font-size: 10px;
  text-align: left;
  margin: 8px 0 2px 7px;
}

.po-instruction-p {
  font-size: 10px;
  height: 60px;
  border: 1px solid black;
  margin: 0;
}

.po-authorised-div {
  display: flex;
}

.po-authorised-head {
  font-size: 10px;
  text-align: left;
  margin: 8px 0 2px 7px;
}

.po-authorised-p {
  font-size: 10px;
  height: 22px;
  width: 109px;
  border: 1px solid black;
  margin: 11px 0 0 13px;
}

.po-total-p1 {
  font-size: 11px;
  margin: 0 0 0 7px;
  text-transform: uppercase;
}

.po-total-p2 {
  font-size: 11px;
  margin: 0 0 0 35px;
}

.po-total-div {
  padding-top: 16px;
}

.po-total-head {
  display: flex;
  width: 100%;
  justify-content: right;
}

.po-card > .modal-yes,
.po-card > .modal-no,
.po-modal-download {
  margin-top: 28px;
  width: 204px;
}

.po-confirmation-p {
  font-weight: 600 !important;
}

.po-download-btn {
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 6px;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 13px 3px !important;
}

.po-download-btn:hover {
  background-color: #ffffff;
  color: #335d87;
}

.po-pickup-p {
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  padding: 5px 12px 0 10px;
  margin-bottom: 40px;
}

.procurement-pickup-modal .modal-content {
  width: 700px !important;
  height: 600px !important;
  background-color: #335d87 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
}

/* purchase order modal css */

/* downloadable po */

.d-po-card-data {
  width: 90%;
}

.d-po-p {
  font-size: 4px;
  margin: 0;
  line-height: 5px;
}

.d-po-p-margin {
  margin-left: 14px;
  margin-bottom: 1px;
}

.d-po-head {
  font-size: 8px;
  margin-bottom: 2px;
}

.po-h6 {
  word-spacing: 5px;
}

.d-po-sub-head {
  margin-top: 4px;
  background-color: #4988dd;
  color: #fff;
  padding: 0px 0 2px 5px;
  font-size: 8px;
  margin-bottom: 2px;
}

.d-po-first-table > thead > tr > th {
  border-width: thin;
  border-color: #020000;
  border-collapse: collapse;
  font-size: 4px;
  background-color: #4988dd;
  text-align: left;
  color: #fff;
  padding: 1px 3px;
}

.d-po-first-table > tbody > tr > td {
  border-width: thin;
  border-color: #020000;
  border-collapse: collapse;
  font-size: 4px;
  text-align: left;
  padding: 1px 3px;
}

.d-po-second-table > tbody > tr > td {
  border-width: thin;
  border-color: #020000;
  border-collapse: collapse;
  font-size: 4px;
  text-align: left;
  padding: 1px 3px;
}

.d-po-second-table > thead > tr > th {
  border-width: thin;
  border-color: #020000;
  border-collapse: collapse;
  font-size: 4px;
  background-color: #4988dd94;
  text-align: left;
  color: #000;
  padding: 1px 3px;
  text-transform: uppercase;
  font-weight: 400;
}

.d-po-first-table,
.d-po-second-table {
  margin-top: 12px;
  width: 100%;
}

.d-po-total-p1 {
  font-size: 4px;
  margin: 0 0 0 7px;
  text-transform: uppercase;
}

.d-po-total-p2 {
  font-size: 4px;
  margin: 0 0 0 19px;
}

.d-po-instruction-head {
  font-size: 6px;
  text-align: left;
  margin: 5px 0 2px 6px;
}

.d-po-instruction-p {
  font-size: 5px;
  height: 20px;
  border: 1px solid #000000a8;
  margin: 0;
}

.d-po-authorised-head {
  font-size: 5px;
  text-align: left;
  margin: 5px 0 2px 6px;
}

.d-po-authorised-p {
  font-size: 5px;
  height: 17px;
  width: 109px;
  border: 1px solid #000000a8;
  margin: 4px 0 0 12px;
}

.pickup-modal-div {
  width: 60%;
  margin: 0 auto;
}

.pickup-modal-btn {
  width: 100%;
  margin: 0;
  margin-top: 20px;
}

.po-modal-form .form-label {
  color: #fff;
}

.no-style,
.no-style:hover {
  text-decoration: none;
  color: #000;
}

/* downloadable po */

/* For Mobile */
@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .net-vendor-table-btn {
    font-size: 14px;
    padding: 10px 8px;
  }

  .margin-lr {
    margin: auto;
  }

  .procurement-vendor {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  .procurement-search-div {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  .procurement-po-modal-content .modal-content {
    width: 370px !important;
    height: 810px !important;
  }

  .receiving-ws-modal-content .modal-content {
    width: 370px !important;
  }

  .po-card {
    width: auto;
    height: 640px;
    margin-top: 20px;
  }

  .po-do-btn-width {
    width: 81%;
  }

  .po-card-data {
    padding: 16px 15px;
  }

  .po-h5 {
    font-size: 16px;
  }

  .po-card > .modal-yes,
  .po-card > .modal-no {
    margin-top: 31px;
    width: 45%;
    margin-right: 11px;
  }

  .modal-action-btns {
    margin-top: 20px;
  }

  .modal-action-btns button:first-child {
    margin-right: 11px !important;
  }

  .modal-action-btns button {
    margin-top: 10px;
    padding: 10px 20px !important;
    width: auto !important;
  }

  .po-pickup-p {
    margin-bottom: 20px;
  }

  .pickup-modal-div {
    width: 85%;
  }

  .heading-actions .actions {
    /* padding-top: 10px !important; */
    text-align: center !important;
    margin: auto;
  }

  .heading-actions {
    text-align: center !important;
  }

  .heading-actions h2 {
    margin-left: 0 !important;
    /* padding-top: 10px !important; */
  }

  .heading-actions {
    display: block !important;
  }

  .parent-heading {
    font-size: 24px;
  }

  .home-paragraph {
    font-size: 16px;
    line-height: 18.13px;
  }

  .home-section-three {
    padding: 18px 0px;
    padding-bottom: 0px;
    border-bottom: 0;
  }

  .driver-trip .trip-item {
    max-width: 100% !important;
  }

  .driver-trip {
    padding: 10px !important;
  }

  .trip-address-details {
    margin-bottom: 15px !important;
    padding: 10px !important;
  }

  .vertical-line {
    left: 40px !important;
  }

  .start-point {
    margin-bottom: -15px !important;
    margin-left: 8px !important;
  }

  .stop-point {
    margin-top: -15px !important;
    margin-left: 8px !important;
  }

  .from_location {
    margin-bottom: -5px !important;
    font-size: 10px !important;
    margin-left: 50px !important;
  }

  .to_location {
    margin-top: -5px !important;
    font-size: 10px !important;
    margin-left: 50px !important;
  }

  .table-section {
    padding-top: 20px;
  }

  .purchase-second-section {
    padding: 40px 15px;
  }

  .modal-form {
    padding: 10px;
  }

  .purchase-card {
    padding: 40px 0;
  }

  .purchase-card-data {
    font-size: 16px;
    padding: 0 0px;
  }

  .search-requests-div {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .purchase-card {
    padding: 40px 10px;
    justify-content: center;
  }

  .purchase-card-data {
    padding: 5px;
    font-size: 16px;
  }

  .admin-hub-card a {
    font-size: 30px;
  }
}

@media screen and (min-device-width: 280px) and (max-device-width: 320px) {
  .margin-lr {
    margin: auto;
  }

  .parent-heading {
    font-size: 22px;
  }

  .home-paragraph {
    font-size: 16px;
    line-height: 18.13px;
  }

  .home-section-three {
    padding: 18px 0px;
    padding-bottom: 0px;
    border-bottom: 0;
  }

  .driver-trip .trip-item {
    max-width: 100% !important;
  }

  .driver-trip {
    padding: 10px !important;
  }

  .trip-address-details {
    margin-bottom: 15px !important;
    padding: 10px !important;
  }

  .vertical-line {
    left: 40px !important;
  }

  .start-point {
    margin-bottom: -15px !important;
    margin-left: 8px !important;
  }

  .stop-point {
    margin-top: -15px !important;
    margin-left: 8px !important;
  }

  .from_location {
    margin-bottom: -5px !important;
    font-size: 10px !important;
    margin-left: 50px !important;
  }

  .to_location {
    margin-top: -5px !important;
    font-size: 10px !important;
    margin-left: 50px !important;
  }

  .table-section {
    padding-top: 20px;
  }

  .modal-action-btns {
    margin-top: 20px;
  }

  .modal-action-btns button:first-child {
    margin-right: 11px !important;
  }

  .modal-action-btns button {
    margin-top: 10px;
    padding: 10px 20px !important;
    width: auto !important;
  }

  .purchase-second-section {
    padding: 40px 5px;
  }

  .modal-form {
    padding: 10px;
  }

  .search-requests-div {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .purchase-card {
    padding: 40px 10px;
    justify-content: center;
  }

  .purchase-card-data {
    padding: 5px;
    font-size: 16px;
  }

  .admin-hub-card a {
    font-size: 24px;
  }

  .heading-actions .actions {
    /* padding-top: 10px !important; */
    text-align: center !important;
    margin: auto;
  }

  .heading-actions {
    text-align: center !important;
  }

  .heading-actions h2 {
    margin-left: 0 !important;
    padding-top: 10px !important;
  }

  .heading-actions {
    display: block !important;
  }
}

/* procurement workspace page css */

/* receiving workspace page css */
.receiving-ws-modal-content .modal-content {
  width: 835px !important;
  min-height: 550px !important;
  background-color: #335d87 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  color: #fff;
}

.receiving-ws-confirmation-modal-content .modal-content {
  width: 379px !important;
  min-height: 387px !important;
  background-color: #335d87 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  color: #fff;
}

.returned-items,
.returned-items-heading {
  display: grid !important;
  grid-template-columns: 1fr 2fr 1fr !important;
}

.returned-items div,
.returned-items-heading div {
  border: 1px solid #000 !important;
  padding: 5px 10px !important;
}

.returned-items div {
  text-align: left !important;
}

/* receiving workspace page css end */
.modal-action-btns button,
.modal-action-btn button {
  color: #ffffff;
  font-size: 14px;
  color: white;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 22px;
  border: 2px solid #ffffff;
  border-radius: 6px;
  font-weight: 600;
  width: 204px;
}

.modal-yes-btn {
  background-color: #c32051;
}

.modal-yes-btn:hover {
  color: #335d87;
  background-color: #ffffff;
  border: 2px solid #c32051;
}

.modal-no-btn {
  background-color: #335d87;
}

.modal-cross-btn {
  text-align: end;
}

.modal-cross-btn img {
  cursor: pointer;
}

.modal-no-btn:hover {
  color: #c32051;
  background-color: #ffffff;
  border: 2px solid #335d87;
}

.modal-action-btns {
  margin-top: 50px;
}

.modal-action-btn {
  text-align: center;
}

.modal-action-btns button:first-child {
  margin-right: 20px;
}

.modal-action-btns.no-margin button {
  margin-right: 0;
}

.sub-table-head-tr {
  border-bottom: 1px solid #000;
}

.returned-item-modal-content .modal-content {
  width: 835px !important;
  min-height: 400px !important;
  background-color: #335d87 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  color: #fff;
}

.returned-item-confirmation-modal-content .modal-content {
  width: 435px !important;
  min-height: 400px !important;
  background-color: #335d87 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  color: #fff;
}

.damage-item-modal-content .modal-content {
  width: 435px !important;
  min-height: 600px !important;
  background-color: #335d87 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  color: #fff;
}

.item-changed-to {
  background: #d9d9d9;
  padding: 11px 27px;
  width: 250px;
  margin: auto;
}

.item-changed-to img:nth-child(2) {
  margin: auto 20px;
}

.modal-comp .modal-content {
  background-color: #335d87;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  color: #fff;
}

.modal-comp .react-select {
  color: #000 !important;
  text-align: left !important;
}

.vendor-modal .modal-content {
  background-color: #ffffff !important;
  border-radius: 20px !important;
  display: block;
  overflow-y: initial !important;
}

.vendor-modal {
  padding: 0 !important;
}

.modal-ok-btn {
  background-color: #c32051;
  width: 252px;
  height: 52px;
  border: 2px solid #ffffff;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 36px;
  font-weight: 600;
}

.modal-ok-btn:hover {
  background-color: #f2f2f2;
  color: #c32051;
  border: 2px solid #c32051;
}

h5 {
  font-size: 20px !important;
}

.down-arrow {
  transition: 0.6s ease;
  rotate: 180deg;
}

.table-responsive {
  box-shadow: 0px 45px 112px rgba(0, 0, 0, 0.06), 0px 22.7812px 48.825px rgba(0, 0, 0, 0.0405),
    0px 9px 18.2px rgba(0, 0, 0, 0.03), 0px 1.96875px 6.475px rgba(0, 0, 0, 0.0195);
  padding: 0;
  margin: 0;
  /* max-height: 500px; */
  overflow: auto;
}

tr.body-tr > td:has(div.icon-div) {
  /* background-color: #fff; */
  cursor: text;
}

tr.body-tr > td:has(div:not(.icon-div)) {
  background-color: #edf6fc;
  cursor: pointer;
}
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium:has(.exact-match) {
  background-color: #39bd8d33; /* Light green background */
}
.hover-border {
  min-width: 200px;
  padding: 5px;
}
.hover-border:hover {
  border: 1px solid #0000004a !important;
  cursor: 'text' !important;
  border-radius: 10px;
}

.exact-match {
  box-sizing: border-box;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium:has(.partial-match) {
  background-color: #fabb1833; /* Light yellow background */
}

.partial-match {
  box-sizing: border-box;
}
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium:has(.data-found) {
  background-color: #fff; /* Light yellow background */
}
.custom-Table-border {
  table-layout: auto; /* Ensure table adjusts based on content */
  padding: 5px;
}

.custom-Table-border .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium div {
  border-radius: 8px;
  width: fit-content;
  height: auto;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-found {
  box-sizing: border-box;
}
.match-cell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-table tr > td:has(div) {
  background-color: white;
  cursor: pointer;
}

.table-responsive table {
  border: none;
  cursor: text;
  background-color: #efefef;
}

.form-check-input {
  cursor: pointer;
}

.input-div {
  text-align: center;
}

.input-div > input,
.input-div > select,
.input-div > textarea {
  border-radius: 3px;
  border: 1.9px solid #335d87;
}

.input-div > input:focus,
.input-div > select:focus,
.input-div > textarea:focus {
  box-shadow: unset;
}

.trip-address-details {
  background: #fff;
  color: #000;
  margin-bottom: 15px;
  border-radius: 24px;
  padding: 15px 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.trip-address-details .badge-comp {
  float: right;
  margin-top: -35px !important;
  margin-right: 5px !important;
}

.trip-address-details img {
  float: right;
  margin-top: -15px !important;
  margin-right: 30px !important;
}

.vertical-line {
  position: relative;
  width: 1px;
  height: 60px;
  background-color: #335d87;
  left: 80px;
}

.start-point {
  font-size: 10px;
  margin-left: 40px;
  margin-bottom: -18px;
  color: #9b9a9a;
}

.stop-point {
  font-size: 10px;
  margin-left: 40px;
  margin-top: -17px;
  color: #9b9a9a;
}

.from_location {
  margin-left: 100px;
  margin-bottom: -12px;
  cursor: pointer !important;
}

.to_location {
  margin-left: 100px;
  margin-top: -14px;
  cursor: pointer !important;
}

.location_link {
  color: #335d87 !important;
  cursor: pointer !important;
}

.from_location a:hover,
.to_location a:hover,
.location_link:hover {
  text-decoration: underline !important;
}

.vertical-line::before,
.vertical-line::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #335d87;
  border-radius: 50%;
}

.vertical-line::before {
  top: -3px;
  left: -2.5px;
}

.sidenav-vertical-line {
  position: absolute;
  width: 1px;
  height: 60px;
  background-color: #000;
  left: 18px;
}

.vertical-line::after {
  bottom: -3px;
  left: -2.5px;
}

.trip-time {
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin-top: 15px;
}

button:disabled,
button[disabled] {
  /* border: 1px solid #999999; */
  background-color: #cccccc;
  color: #666666;
}

.driver-trip {
  padding: 30px 50px;
  background: #f2f2f2;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  margin-bottom: 70px;
}

.driver-trip .trip-item {
  margin: auto;
  text-align: left;
  max-width: 700px;
  margin-top: 40px;
}

.driver-trip .trip-name {
  float: left;
}

.driver-trip .trip-date {
  margin-top: -30px !important;
  float: right;
}

.driver-item-detail-modal .driver-item-detail-modal-header {
  padding: 0;
}

.driver-item-detail-modal .driver-item-detail-modal-header-btn {
  width: 50%;
  padding: 15px;
  cursor: pointer;
}

.driver-item-detail-modal .driver-item-detail-modal-header-btn:first-child {
  border-top-left-radius: 7px;
}

.driver-item-detail-modal .driver-item-detail-modal-header-btn:last-child {
  border-top-right-radius: 7px;
}

.driver-item-detail-modal .driver-item-detail-modal-contact-details {
  margin: auto;
  text-align: center;
  width: 80%;
}

.driver-item-detail-modal .driver-item-detail-modal-contact-details p {
  margin-bottom: 30px;
}

.driver-item-detail-modal .driver-item-detail-modal-contact-details h5 {
  text-decoration: underline;
}

.driver-item-detail-modal .driver-item-detail-modal-footer {
  background: #335d87;
  text-align: center;
  color: #fff;
  display: block;
}

.driver-item-detail-modal .driver-item-detail-modal-footer div {
  cursor: pointer;
}

.driver-item-detail-confirmation-modal .modal-body {
  padding: 20px !important;
}

.admin-hub-card {
  text-align: center;
  background: #f2f2f2;
  padding: 50px;
  max-width: 300px;
  height: 220px;
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: 700;
  border-radius: 20px;
  border: 3px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
  cursor: pointer;
  --outline: 2px solid #335d87;
  outline: var(--outline);
  border: var(--outline);
  outline-offset: -2px;
  transition: outline-width 200ms ease, outline-offset 200ms ease;
}

.admin-hub-card a {
  text-decoration: none;
  color: #000;
}

.admin-hub-card:hover {
  outline: var(--outline);
  outline-width: 4px;
  outline-offset: -4px;
}

.heading-actions {
  display: grid;
  grid-template-columns: 9fr 1fr;
}

.heading-actions h2 {
  margin-left: 245px;
}

.heading-actions .actions {
  text-align: center !important;
  /* padding-top: 68px; */
  padding-bottom: 10px;
  font-size: 36px;
  width: max-content;
}

.modal-close-btn {
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
  margin-top: -10px;
}

.badge-comp {
  padding: 5px 10px 7px 10px;
  border-radius: 20px;
}

.badge-comp-success {
  background: #ecfdf3;
  color: #027a48;
}

.badge-comp-danger {
  background: #fef3f2;
  color: #bc3a31;
}

.badge-comp-info {
  background: #eff8ff;
  color: #175cd3;
}

.pagination-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  /* margin: 10px; */
  /* border-bottom: 1px solid #7993ae;
  border-left: 1px solid #7993ae;
  border-right: 1px solid #7993ae; */
  padding: 10px;
}

.pagination-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination-button {
  border: 1px solid #ccc;
  border-radius: 3px;
  /* padding: 5px 10px; */
  font-size: 16px;
  line-height: 35px;
  cursor: pointer;
  width: 40px;
  font-weight: bold;
}

.pagination-info {
  font-size: 14px;
}

.pagination-input {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.pagination-input-field {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  width: 40px;
  font-size: 14px;
}

.pagination-dropdown select {
  font-size: 16px;
  width: fit-content;
}

.active-inactive-admin-ws-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.active-inactive-admin-ws-btn {
  width: max-content;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 5px;
}

.active-inactive-admin-ws-btn div:first-child {
  margin-right: 10px;
}

.active-inactive-admin-ws {
  padding: 6px 10px;
  border-radius: 5px;
  display: inline;
  cursor: pointer;
  transition: all 0.3s;
}

.active-inactive-admin-ws div {
  display: inline;
}

.active-inactive-admin-ws.active {
  background: #fafafa;
  color: #335d87;
}

.active-inactive-admin-ws span {
  font-size: 12px;
  color: #fff;
  background: #c32051;
  padding: 4px 5px;
  border-radius: 8px;
}

.status-btn {
  background-color: #d6dfe7;
  margin-right: 10px;
  border: none;
  border-radius: 10px;
  padding: 8px 12px;
  width: max-content;
  display: inline;
  cursor: pointer;
}

.status-btn.active {
  background-color: #bac1c8;
}

.bulk-update-job-modal .modal-content {
  text-align: left;
}

.bulk-update-job-modal .modal-action-btns {
  text-align: center;
  margin-top: 30px;
}

.bulk-delet-ocr-item-modal .modal-action-btns {
  margin-top: 30px;
}

.disable-icon {
  opacity: 0.4;
}

.create-customer-contact-modal .modal-content {
  background-color: #fff;
  padding: 30px 0 60px 0;
}

.customer-contact input,
.net-vendor-label input,
.customer-contact select,
.net-vendor-label select,
.customer-contact textarea,
.net-vendor-label textarea,
.phone-input {
  border-radius: 3px;
}

.phone-input input {
  height: 54px !important;
  border: 0.5px solid #cbcbcb;
}

.customer-contact input:focus,
.net-vendor-label input:focus,
.customer-contact select:focus,
.net-vendor-label select:focus,
.customer-contact textarea:focus,
.net-vendor-label textarea:focus {
  box-shadow: unset;
}

.customer-contact {
  color: black;
  text-align: left;
}

.customer-cancel-btn {
  float: right;
  background-color: #ffffff;
  color: #335d87;
  border: 2px solid #335d87;
  border-radius: 6px;
  font-weight: 600;
  width: 204px;
  margin-top: 30px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 22px;
  font-size: 14px;
}

.customer-cancel-btn:hover {
  color: #ffffff;
  background-color: #335d87;
  border: 2px solid #335d87;
}

.customer-add-btn {
  float: left;
  background-color: #335d87;
  color: #ffffff;
  border: 2px solid #335d87;
  border-radius: 6px;
  font-weight: 600;
  width: 204px;
  margin-top: 30px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 22px;
  font-size: 14px;
}

.customer-add-btn:hover {
  color: #335d87;
  background-color: #ffffff;
  border: 2px solid #335d87;
}

.customer-contact-heading {
  text-align: center;
  padding-bottom: 25px;
  margin-bottom: 60px;
  font-size: 36px;
  color: black;
  border-bottom: 1px solid #335d87;
}

.customer-contact-name {
  color: white;
  background-color: #c32051;
  border-radius: 5px;
  padding: 6px 20px;
  font-size: 16px;
  width: fit-content;
}

.driver-switch-btn {
  float: right;
  top: 10px;
}

.driver-switch-btn > .switch-btn-slider {
  background-color: #335d87;
}

/* For Mobile */
@media screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .driver-switch-btn {
    top: -54px;
  }

  .driver-trip .trip-date {
    margin-top: -25px !important;
  }
}

.select-style {
  border: 1.9px solid #335d87;
  border-radius: 3px;
}

.ocr-bill-text {
  font-weight: 400;
  margin-top: 40px;
}

.ocr-bill-span-bold {
  font-weight: 600;
}

.ocr-bill-span-paid {
  font-weight: 600;
  color: #2ed62e;
}

.add-or-update-trip-stop-modal .row {
  text-align: left;
}

.add-or-update-trip-stop-modal .modal-action-btns {
  text-align: right;
}

.add-or-update-trip-stop-modal .modal-action-btns button:not(:last-child) {
  margin-right: 10px;
}

.add-or-update-trip-stop-modal .modal-action-btns .discard-btn {
  color: #335d87;
  background: #f2f2f2;
}

.add-or-update-trip-stop-modal .modal-action-btns .delete-btn {
  color: #fff;
  background: #c30251;
}

.add-or-update-trip-stop-modal .edit-heading {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.add-or-update-trip-stop-modal .edit-heading .trip-name {
  text-align: right;
}

.add-or-update-trip-stop-modal .edit-heading .trip-name span {
  padding: 5px 10px;
  background-color: #348189;
  border-radius: 5px;
}

.add-or-update-trip-stop-modal .modal-action-btns .add-stop-btn {
  color: #fff;
  background: #335d87;
}

.add-stop-attachement-btn {
  font-weight: 600;
  padding: 11px 23px;
  font-size: 16px;
  text-transform: uppercase;
  background-color: #335d87;
  margin-top: 16px;
  cursor: pointer;
  border-radius: 6px;
  color: white;
  border: 2px solid #fff;
}

.deleteTransportationRequestModal .modal-content {
  width: 370px;
}

.payment-schedule-btn button {
  padding: 10px 18px;
}

.payment-discard-btn {
  padding: 8px 21px;
  border: 1px solid #335d87;
  background-color: white;
  color: #335d87;
  border-radius: 3px;
  font-weight: 600;
  text-transform: uppercase;
}

.payment-discard-btn:hover {
  padding: 8px 21px;
  border: 1px solid #335d87;
  background-color: #335d87;
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  text-transform: uppercase;
}

.payment-create-btn {
  padding: 8px 21px;
  border: 1px solid #335d87;
  background-color: #335d87;
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  text-transform: uppercase;
}

.payment-create-btn:hover {
  padding: 8px 21px;
  border: 1px solid #335d87;
  background-color: #fff;
  color: #335d87;
  border-radius: 3px;
  font-weight: 600;
  text-transform: uppercase;
}

.payment-plus-icon {
  width: 6%;
  margin: 0 auto;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.react-select .css-13cymwt-control {
  border: 1.9px solid #335d87;
}

/* slider css */

.wrapper {
  position: relative;
  top: 0;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.panel {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;

  &._active {
    z-index: 10;
  }
}

.trans-layer,
.section {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.trans-layer {
  transform: translateY(-100%) skew(0) rotate(0) scale(2);
  border-radius: 50%;
  transition: all 500ms ease-in-out;
  transition-delay: 500ms;

  ._active & {
    transform: translateY(0) skew(0) rotate(0) scale(2);
    transition-delay: 0ms;
  }
}

.section {
  transform: translateY(100%);
  transition: all 450ms ease-in-out;
  transition-delay: 0ms;

  ._active & {
    transform: translateY(0);
  }
}

.section-content {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: arial;
  text-transform: uppercase;
  opacity: 0;
  transform: translateY(30px);
  transition: all 200ms ease;
  transition-delay: 0s;

  ._active & {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: 1000ms;
  }
}

.nav-dots {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 20;
  width: 40px;
}

.nav-dot {
  width: 10px;
  height: 10px;
  margin: 20px auto;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  transition: all 300ms ease-out;

  &.active {
    background: red;
  }
}

.next-btn,
.prev-btn {
  width: 20px;
  height: 10px;
  position: absolute;
  z-index: 20;
  color: #141313;
  line-height: 10px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  border: none;
}

.next-btn {
  bottom: 50%;
  right: 14px;
  margin-top: -300px;
  /* Adjust the margin to create space */
}

.prev-btn {
  top: 50%;
  right: 14px;
  margin-top: -300px;
  /* Adjust the margin to create space */
}

@media screen and (min-device-width: 360px) and (max-device-width: 767px) {
  .prev-btn,
  .next-btn {
    display: none;
  }
}

/* slider css */

.accordion-header {
  border: 1px solid #eee !important;
}

.payment-modal-table {
  width: 98%;
  margin: 0 auto;
}

.payment-modal-table td {
  border: 1px solid black;
  color: black;
}

.driver-yes-btn {
  background-color: #c32051;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 36px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  font-weight: 600;
  border: 2px solid #fff;
}

.driver-yes-btn:hover {
  color: #335d87;
  background-color: #ffffff;
  border: 2px solid #c32051;
}

.driver-no-btn {
  background-color: #335d87;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px 36px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  font-weight: 600;
}

.driver-no-btn:hover {
  color: #335d87;
  background-color: #ffffff;
  border: 2px solid #335d87;
}

a {
  color: #000 !important;
  text-decoration: none !important;
}

a:hover {
  color: #000 !important;
  text-decoration: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.component-heading {
  position: sticky;
  top: 60px;
  background-color: #fff;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.chat-icon {
  margin: 0 0 10px 20px;
  cursor: pointer;
}

.chat-modal .modal-content {
  background-color: white;
}

.chat-modal .modal-header {
  background-color: white;
  border: 0px;
}

.chatbox-h3 {
  color: black;
  margin-top: 30px;
}

.chat-input-w {
}

.chatbox-height {
  margin-top: 80px;
}

.chatbox-input {
  width: 77%;
  height: 70px;
  padding-right: 50px;
}

.chatbox-btn {
  margin-left: -57px;
  height: 57px;
  width: 4.5%;
  background-color: #c32051;
  color: white;
  border-radius: 10px;
  -webkit-appearance: none;
  margin-top: 6px;
}

.chatbox-div {
  min-height: 400px;
}

.chatbox-content-div {
  height: 430px;
  overflow: auto;
}

.chatbox-messages-div {
  text-align: left;
}

.chatbox-div h5 {
  color: black;
}

.chatbox-div p {
  color: black;
  font-size: 16px;
  font-weight: 400;
}

.chatbox-ml {
  margin-left: 16px;
}

.chatbox-h5-margin {
  margin-bottom: 5px;
}

.chatbox-user-message {
  margin-left: 80px;
}

.vendor-dropdown-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.vendor-dropdown-tooltip .vendor-dropdown-tooltip-text {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  width: 180px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.vendor-dropdown-tooltip:hover .vendor-dropdown-tooltip-text {
  visibility: visible;
}

.payment-modal-table th,
.payment-modal-table td {
  border: 1px solid rgb(103, 103, 103) !important;
}

.payment-modal-table {
  margin: 0 0 20px 0;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.custom-radio {
  accent-color: #4e6e8b;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
